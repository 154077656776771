.input-masked-date-date-activation-2, .input-masked-date-date-expiration-2 {
    margin: 0px!important;
    margin-left: 5px!important;
    width: 100%!important;
}

.input-masked-date-date-activation-2 {
    margin-left: 0px!important;
    margin-right: 10px!important;
} 
.input-masked-date-date-expiration-2 {
    margin-left: 10px!important;
}
.date-picker-masked-date-date-activation-2{
    position: absolute!important;
    right: 76%!important;
    width: 100px!important;

    div {
        position: relative;
        width: 40px!important;
        height: 97%!important;
        left: 20%;
    }

    .ant-calendar-picker-icon {
        position: relative!important;
        right: -570%!important;
        top: 1px!important;
    }
}

.date-picker-masked-date-date-expiration-2{
    position: absolute!important;
    right: 27%!important;
    width: 100px!important;

    div {
        position: relative;
        width: 40px!important;
        height: 97%!important;
        left: 20%;
    }

    .ant-calendar-picker-icon {
        position: relative!important;
        top: 1px!important;
        right: -515%!important;
    }
}

.legend-fieldset {
    width: min-content;
    margin: none!important;
}

fieldset {
    padding: 13px!important;
    border: 2px solid #313131!important;

    .legend-fieldset {
        margin: 0 0 0 0;
    }

    .coach-edit-phone-number {
        width: 300px!important;
    }

}
.react-phone-number-input__country-select {
    background-color: #fff;
    color: #17191aba;
}

.coache-edit-container > fieldset > .centers-select {
    margin: 5px 0 !important;
}