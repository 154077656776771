.content-modal-audit {
    width: 50%!important;
    .ant-modal-content {
        .ant-modal-body {
            padding-top: 2px;
        }
        .ant-modal-title{
            font-size: 18px;
        }

    }

    .container-switch-pretty {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        margin-top: 11px;
    }
}