.pagination {
    display: flex;
    justify-content: flex-end;

    .ant-pagination-item {
        display: none;
    }

    .ant-pagination-jump-next {
        display: none;
    }
}

.report-status {
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    color: white !important;
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 4px;
}

.report-status.PENDING {
    background-color: #FFD12E;
}

.report-status.WAITING_ANALYSIS {
    background-color: #FF5C00;
}

.report-status.ANALYSIS {
    background-color: #FFC36A;
}

.report-status.WAITING_SIGNED {
    background-color: #0B89FE;
}

.report-status.WAITING_CORRECTION {
    background-color: #7A72F7;
}

.report-status.SIGNED {
    background-color: #00D5EA;
}

.report-status.APPROVED {
    background-color: #2EA568;
}

.report-status.REJECTED {
    background-color: #F23232;
}
