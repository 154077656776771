@import '../../assets/scss/utils';

.integrations-container {

    form {
        width: 600px;
        margin: 30px 0 0;
        @include displayFlex(column, nowrap, center, center, center);
        padding: 20px;

        background: #313131;
        border-radius: 10px;
    }
}