.container-table-audit {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    width: 100%;
    overflow-x: auto;
    .ant-table-wrapper {
        width: 100%;
    }
}