@import '../../../assets/scss/utils';

.panelcontrol-pagination {
    @include displayFlex(row, nowrap, flex-end, center, center);
    margin-top: 20px !important;
}

.table-panelcoaching, .coach-list, .table-resources {
    .ant-table-thead > tr > th:nth-child(3){
        width: 30px;
    }    
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        text-align: center;
        &:nth-child(1), &:nth-child(2){
            text-align: left;
        }
        &:nth-child(n+3){
            width: 100px;
        }
    }
}

.dashboard-content .ant-table-wrapper {

    .ant-table-thead > tr > th {
        background: #2b2b2b!important;
        border-bottom: 1px solid #17191a !important;

        span {
            color: #fcfbfc;
        }

    }

    .ant-table-row {
        td > td {
            border: 0 !important;
        }
        
      
        &:hover,
        &:focus {
            box-shadow: 0px 0px 5px 0 #000;
        }
        

        &:nth-child(odd) {
            background: #2b2b2b !important;
        }

        &:nth-child(even) {
            background:#313131;
        }
    }


    .ant-table-tbody > tr > td {
        border: 0 !important;
    }

    td {
        color: #fcfbfc;
    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background: #125194 !important;
    }
}

.ant-input, .ant-table-filter-dropdown, .ant-dropdown-menu, .ant-table-filter-dropdown-btns {
    background: #2b2b2b!important;
    color: #ffffff!important;
}