.import-result-container {
    .collapse-success {
        .ant-collapse-header {
            color: #3c763d;
            background-color: #dff0d8;
            border-color: #d6e9c6;
        }
    }

    .collapse-error {
        .ant-collapse-header {
            color: #a94442;
            background-color: #a9444233;
            border-color: #ebccd1;
        }
    }

    .buttons-import-result {
        padding: 20px 0;
        display: flex;
        justify-content: flex-end;
        .button-color-import > span{
            color: #312e2e!important;
        }
        button {
            margin-left: 20px;
        }
    }
}