@import '../../assets/scss/utils';

.coach-report-list {
    .ant-table-row {
        font-size: 13px !important;
    }
}

.coach-report-list-pagination {
    @include displayFlex(row, nowrap, flex-end, center, center);
    margin-top: 20px !important;

    .ant-pagination-total-text {
        color: #fcfbfc;

        b {
            font-size: 16px;
            color: #f0ebf0;
        }
    }
}

.dashboard-content {
    .ant-pagination-item-link {
        background-color: #313131 !important;
        border: 0 !important;

        i {
            color: #fcfbfc;
        }

        &:hover {
            background-color: #1b86f8 !important; 
        }
    }

    .ant-pagination-item {
        background-color: #313131;
        border: 0 !important;

        a {
            color: #fcfbfc;
        }

        &:hover {
            background-color: #1b86f8;

            a {
                color: #fcfbfc;
            }  
        }
    }

    .ant-pagination-item-active {
        background: #1b86f8;
    }

    .ant-pagination-jump-next {
        background-color: #313131 !important;

        span {
            color: #fcfbfc !important; 
        }
    }
}

.icons-action {
    i {
        font-size: 18px;
    }

    .canceled {
        color: red;
    }

    .active {
        &:hover {
            cursor: pointer;
            color: $c-link;
        }
    }
}

.row-canceled {
    &:nth-child(n) {
        text-decoration: line-through;
        text-decoration-color: #fff;
    }
}

.report-status {
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    color: white;
    background-color: #0000ff;
    font-size: 10px;

    &.created {
        background: #F7CA18;
    }

    &.processing {
        background: #ebd16b;
    }

    &.ready {
        background-color: green;
    }

    &.removed {
        background: #E26A6A; 
    }

    &.error {
        background: #d64444; 
    }
}

.report-action + .report-action {
    margin-left: 10px;
}

.report-actions {
    display: flex;
}

.report-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
}

.found-coach-report {
    text-align: right;
    margin-top: 10;
    margin-bottom: -15;
}