$bg-content: #fbfcfd;
$c-link: #1890ff;
$dev: #5a4822d6;
$alpha: #1c1e1fd9;
$prod: #211b0eab;


$footer-height: 50px;

// ** Display Flex
@mixin displayFlex($direction, $wrap, $justify, $item, $content) {
    display: flex;
    flex-flow: $direction $wrap;
    justify-content: $justify;
    align-items: $item;
    align-content: $content;
}