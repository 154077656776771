@import '../../../assets/scss/utils';

.container-filters {
    display: flex;
    gap: 10px;
    align-items: center;

    .input-filter {
        @include displayFlex(column, nowrap, flex-end, flex-start, flex-start);
        margin-right: 10px;

        label {
            color: #e1e1e1;
            min-height: 25px;
        }

        .ant-input-search {
            width: 100%;
            max-width: 265px;
        }
        .select-action {
            width: 100%;
            min-width: 265px;
            margin-right: 10px;
        }
    
    }

    .container-audit-search {
        margin-top: 20px;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .input-filter {
            width: 100%;
            .ant-input-search {
                max-width: 100%;
            }
            .select-action {
                max-width: 100%;
                min-width: 20px;
            }
        }

        .container-audit-search {
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            margin-bottom: 10px;
        }
    }
}