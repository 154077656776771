@import '../../../assets/scss/utils';

.user-list {
    .ant-table-row {
        font-size: 13px !important;
    }
}

.users-pagination {
    @include displayFlex(row, nowrap, flex-end, center, center);
    margin-top: 20px !important;

    .ant-pagination-total-text {
        color: #fcfbfc;

        b {
            font-size: 16px;
            color: #f0ebf0;
        }
    }
}

.dashboard-content {
    .ant-pagination-item-link {
        background-color: #313131 !important;
        border: 0 !important;

        i {
            color: #fcfbfc;
        }

        &:hover {
            background-color: #1b86f8 !important;
        }
    }

    .ant-pagination-item {
        background-color: #313131;
        border: 0 !important;

        a {
            color: #fcfbfc;
        }

        &:hover {
            background-color: #1b86f8;

            a {
                color: #fcfbfc;
            }
        }
    }

    .ant-pagination-item-active {
        background: #1b86f8;
    }

    .ant-pagination-jump-next {
        background-color: #313131 !important;

        span {
            color: #fcfbfc !important;
        }
    }
}

.icons-action {
    i {
        font-size: 18px;
    }

    .canceled {
        color: red;
    }

    .active {
        &:hover {
            cursor: pointer;
            color: $c-link;
        }
    }
}

.row-canceled {
    &:nth-child(n) {
        text-decoration: line-through;
        text-decoration-color: #fff;
    }
}
