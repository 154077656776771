.content-modal-profile {
    .ant-modal-content {
        // width: 600px!important;
        // height: 570px!important;
        width: 100%;
        height: 100%;
        .ant-modal-body {
            padding-top: 2px;
        }
        .ant-modal-title{
            font-size: 18px;
        }
    }
}

.ant-descriptions-item-label {
    background-color: rgb(27, 27, 27) !important;
    color: #ffff!important;
    border: 1px solid #313131!important;
}

.ant-descriptions-item-content {
    border: 1px solid #8c8989!important;
    color: #ffff!important;
    border: 1px solid #313131!important;
}

.ant-descriptions-row, .ant-descriptions-view {
    border: 1px solid #313131!important;
}
.ant-descriptions-item-colon {
    border: 1px solid #313131!important;
}
.ant-descriptions-title {
    color: #fff!important;
}