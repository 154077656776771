@import './assets/scss/utils';

body {

    .label-form {
        font-weight: bold;
        display: block;
    }

    input,
    .ant-select,
    .ant-radio-group {
        margin: 5px 0 !important;
    }

    .ant-calendar-picker {
        width: 100%;
    }

    .form-group {
        @include displayFlex(row, nowrap, space-between, center, center);

        input,
        .ant-select,
        .ant-calendar-picker {
            width: calc(50% - 10px);
        }

        .ant-calendar-picker-input {
            width: 100%;
        }
    }

    .tags-status {
        text-transform: uppercase;
        border: none;
        font-weight: bold;
        color: white;
        background-color: #0000ff;
        font-size: 10px;

        &.master {
            background-color: #E87E04;
        }

        &.executive {
            background: #e7505a;
        }

        &.business {
            background: #44ab4c;
        }

        &.career {
            background-image: linear-gradient(to left, #0b6cfd, #0059f2, #0045e5, #0030d8, #0012c9);
        }

        &.trainer {
            background-image: linear-gradient(to left, #c0bd08, #9e9c22, #6d6c1f, #6b6a27, #525110);
        }

        &.livingFamily {
            background-image: linear-gradient(to left, #1cefa2, #3ec978, #8bc34a, #1c873f, #177e0e);
        }

        &.microfranchisee {
            background: #c0bd08;
        }

        &.mindfulness {
            background-color: #32c5d2;
        }

        &.EXPERIMENTAL,
        &.coach {
            background-color: #0000ff;
        }

        &.EXPIRADO {
            background: #E26A6A;
        }

        &.ATIVO,
        &.VÁLIDO {
            background-color: green;
        }

        &.CANCELADO {
            background: #525e64 !important;
        }

        &.ATENÇÃO {
            background: #F7CA18 !important;
        }

        &.RECORRENTE_PERIODO_EXTRA {
            background: #b3b300 !important;
        }

        &.INATIVO {
            background-color: #3c3c3c !important;
        }

        &.ATENÇÃO {
            background: #b3b300 !important;
        }

        &.PRONTO {
            background-color: #8b1820;
        }

        &.SEM_LICENCA {
            background-color: rgb(202, 137, 57);
        }

        &.ENCERRADO {
            background-color: rgb(57, 115, 202);
        }
    }
}

.ant-modal-close {
    padding: 10px;
    .ant-modal-close-icon {
        color: #fff;
        font-size: 20px;
    }
}
.ant-modal-header {
    border-radius: 0px!important;
    border-top-left-radius: 5px!important;
    border-top-right-radius: 5px!important;
}
.ant-modal-body {
    border-bottom-left-radius: 5px!important;
    border-bottom-right-radius: 5px!important;
}
.ant-select-selection {
    background: #2b2b2b!important;
}
.ant-input {
    border-radius: 4px !important;
    border: 1px solid #17191a!important;
}
.ant-input-number {
    border-radius: 4px !important;
    border: 1px solid #17191a!important;
}
span.ant-checkbox-wrapper > .ant-checkbox {
    span {
        font-weight: 800;
        color: #fff!important;
    }
}
.ant-modal > .ant-modal-content {
    background-color: TRANSPARENT!important;
}
.ant-descriptions-title  {
    color: #fff!important;
}
span, h1, h2, h3, label {
    color: #fff;
}
.ant-table-column-title {
    font-weight: 800!important;
}
.ant-collapse-content-active {
    background-color: #1f1d1d!important;
}
.ant-empty-description {
    color: #fff!important;
}
.ant-table-placeholder {
    border: none !important;
    background-color: #1f1d1d!important;
    border-top: 1px solid black!important;
}
.react-phone-number-input__input::placeholder {
    color: #bab8b0;
    padding: 10px;
    opacity: 1;
}
.react-phone-number-input__input {
    padding: 10px;
}

.ant-calendar-table {
    .ant-calendar-column-header {
        span {
            color: #3c3c3c!important;
        }
    }
}

.ant-notification-topRight {
    margin: 0!important;
    position: absolute!important;
    top: 50%!important;
    left: 40%!important;
    transform: translate(0, -110%)!important;
}

button[disabled] {
    background-color: #bab8b075!important;
    border: none;
    span {
        color: #3c3c3c!important;
    }
}

.ant-select-clear-icon {
    font-size: 18px;
    background-color: #2b2b2b!important;
    svg {
        font-size: 13px;
        color: #fff;
    }
}
