.div-enrollment {
    display: flex;
    align-items: center;
    span {
        width: 201px;
        margin-right: 10px ;
    }
    .span-enrollment {
        width: 155px!important;
    }
    .input-enrollment {
        width: 629px!important;
        margin-right: 10px!important;
    }
}
.text-enrollment {
    margin-top: 10px;
    color: #fff;
    font-size: 15px;
    span {
        display: block;
    }
    .small-text {
        margin-top: 10px;
        font-size: 12px;
    }
}
.date-picker-masked-date-enrollment-date {
    width: 0!important;
    position: absolute;
    right: 357px;
    .ant-calendar-picker-icon {
        position: relative!important;
        top: 0px!important;
        right: -335px !important;
    }
}
.input-masked-date-enrollment-date {
    margin-left: 5px!important;
}
