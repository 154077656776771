@import '../../../assets/scss/utils';

.contact-us-pagination {
    @include displayFlex(row, nowrap, flex-end, center, center);
    margin-top: 20px !important;

    .contac-us-text-pagination {
        font-size: 18px;
        padding: 5px;
        margin-right: 30px;

    }
    .contact-us-dropdown {
        margin-top: 20px !important;
    }
    .ant-pagination-item {
        display: none !important;
    }

}

.message-list {
    .anticon-fullscreen{
        font-size:  18px;
        margin-left: 12px;
    }

    .ant-table-thead > tr > th:nth-child(3){
        width: 30%!important;
    }

    .ant-table-thead > tr > th:nth-child(4){
        width: 30px;
    }

    .ant-table-row  {
        td:nth-child(3){
            text-align: left!important;
        }
    }
}

.ant-table-column-title {
    font-size: 13px!important;
    font-weight: 500;
}
