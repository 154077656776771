.container-json-viewer-pretty {

    .json-container {
        font-style: normal;
        font-weight: bold;
        line-height: 1.4em;
        font-size: 0.9rem;
        transition: background-color 400ms;
    }

    a.json-link {
        text-decoration: none;
        border-bottom: 1px solid;
        outline: none;
    }

    a.json-link:hover {
        background-color: transparent;
        outline: none;
    }

    ol.json-lines {
        white-space: normal;
        padding-inline-start: 3em;
        margin: 0px;
    }

    ol.json-lines>li {
        white-space: pre;
        text-indent: 0.7em;
        line-height: 1.5em;
        padding: 0px;
    }

    ol.json-lines>li::marker {
        font-family: system-ui, sans-serif;
        font-weight: normal;
    }

    .json-key,
    .json-string,
    .json-number,
    .json-boolean,
    .json-null,
    .json-mark,
    a.json-link,
    ol.json-lines>li {
        transition: all 400ms;
    }

    /* Colors */
    .json-container {
        background-color: white;
    }

    .json-key {
        color: brown;
    }

    .json-string {
        color: olive;
    }

    .json-number {
        color: navy;
    }

    .json-boolean {
        color: teal;
    }

    .json-null {
        color: dimgray;
    }

    .json-mark {
        color: black;
    }

    a.json-link {
        color: purple;
    }

    a.json-link:visited {
        color: slategray;
    }

    a.json-link:hover {
        color: blueviolet;
    }

    a.json-link:active {
        color: slategray;
    }

    ol.json-lines>li::marker {
        color: dimgray;
    }

    ol.json-lines>li:nth-child(odd) {
        background-color: gainsboro;
    }

    ol.json-lines>li:nth-child(even) {
        background-color: whitesmoke;
    }

    ol.json-lines>li:hover {
        background-color: lemonchiffon;
    }

    .dark-mode .json-container {
        background-color: black;
    }

    .dark-mode .json-key {
        color: indianred;
    }

    .dark-mode .json-string {
        color: darkkhaki;
    }

    .dark-mode .json-number {
        color: deepskyblue;
    }

    .dark-mode .json-boolean {
        color: mediumseagreen;
    }

    .dark-mode .json-null {
        color: darkorange;
    }

    .dark-mode .json-mark {
        color: silver;
    }

    .dark-mode a.json-link {
        color: mediumorchid;
    }

    .dark-mode a.json-link:visited {
        color: slategray;
    }

    .dark-mode a.json-link:hover {
        color: violet;
    }

    .dark-mode a.json-link:active {
        color: slategray;
    }

    .dark-mode ol.json-lines>li::marker {
        color: silver;
    }

    .dark-mode ol.json-lines>li:nth-child(odd) {
        background-color: #222222;
    }

    .dark-mode ol.json-lines>li:nth-child(even) {
        background-color: #161616;
    }

    .dark-mode ol.json-lines>li:hover {
        background-color: dimgray;
    }
}

.container-json-viewer-colors {
    span, h1, h2, td {
        color: #fff!important;
    }
}