.labs-profile-profile {
    .ant-avatar {
        width: 75px!important;
        height: 80px!important;
    }
    .photo-url-modal-profile{
        display: flex!important;
        flex-direction: column-reverse!important;
        justify-content: center!important;
        align-items: center!important;
        button {
            margin-top: 5px!important;
        }
    }
}