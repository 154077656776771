.group-input {
    padding: 10px;
    display: flex;
    justify-content: space-around;
};

.input-group {
    width: 70%;
    display: grid;
};

.attachments {
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
};