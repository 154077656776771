.content-input-modal-units {
    .ant-input {
        margin-bottom: 15px!important;
    }

    .ant-btn-primary {
        width: 100% !important;
        margin-top: 15px!important;
        span {
            font-weight: 800;
        }
    }

}

.button-modal-units {
    display: flex;
    justify-content: flex-end;
    .ant-btn-primary {
        margin-top: 20px;
    }
}
div.content-input-modal-units > label.ant-checkbox-wrapper.ant-checkbox-wrapper > span {
    color: #fff!important;
    span {
        font-size: 800!important;
    }
}
div.content-input-modal-units > label.ant-checkbox-wrapper > span:nth-child(2) {
    text-transform: uppercase;
    font-weight: 800;
}
.body > div:nth-child(9) > div > div.ant-modal-wrap > div > div.ant-modal-content {
    background-color: #fff!important;
}
.photo-url-modal-profile > .ant-btn > span {
    color: black!important;
}
div.content-input-modal-units > label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked > span {
    color: #ffffff!important;
}