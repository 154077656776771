@import '../../../assets/scss/utils';

.register-units-filter {
    @include displayFlex(row, wrap, space-between, center, center);

    margin-bottom: 25px!important;

    .container-register-units-search {
        @include displayFlex(row, nowrap, flex-end, center, center);
        width: 400px;
        button {
            margin-left: 5px;
        }
    }
}