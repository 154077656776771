@import '../../assets/scss/utils';

.side-menu-container {
    height: calc(100vh - #{$footer-height});
    overflow: auto;
    transition: all .5s;
    z-index: 1;

    box-shadow: 0px -5px 5px #0000;
    background: #313131;

    &.open {
        width: 300px;
        min-width: 300px
    }

    &.close {
        width: 80px;
        min-width: 80px
    }

    ul {
        background: #313131;
        border-right: 0;

        li {
            align-items: left!important;
            span {
                padding-left: 29px;
            }
        }

        .ant-menu-submenu,
        .ant-menu-item {
            display: flex;
            justify-content: center;
            flex-flow: column wrap;
            padding: 2px !important;

            &:last-child {
                margin-bottom: 0 !important;
            }

            svg {
                width: 16px;
                height: 16px;
                path, rect {
                    fill: #bdbdbd;
                }
            }
        }
    }

    button {
        width: 100%;
        height: 50px;
        padding: 20px 10px;

        background: #292929;
        border-radius: 0;
        border: 0;
        align-items: center;

        .anticon {
            font-size: 18px;
            color: #bdbdbd;
        }

        &:hover,
        &:focus {
            background: #292929;
        }

        &:hover {
            .anticon {
                color: #1b86f8;
            }
        }
    }

    .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
        padding: 0 !important;
    }
}

.ant-menu {
    background: #313131 !important;

    .ant-menu-sub {
        background-color:#17191a!important
    }

    .ant-menu-item-group {
        margin-bottom: 0 !important;
    }

    .ant-menu-item-group-title {
        padding: 0;
    }

    .ant-menu-item-group-list {
        background-color:#17191a!important;

        .ant-menu-item {
            padding: 4px 20px !important;

            .anticon {
                line-height: initial !important;
            }

            span {
                font-size: 14px;
            }

            svg {
                width: 14px;
                height: 14px;
            }
        } 
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
        height: auto !important;
        padding: 10px 20px !important;
        margin-bottom: 0 !important;
        span {
            color: #bdbdbd;
        }

        &:hover {
            span {
                color: #1b86f8;
            }

            svg {
                path, rect {
                    fill: #1b86f8;
                }
            }
        }

        &.ant-menu-item-selected {
            background-color: #17191a!important;
        }

        &:hover,
        &:active,
        &:focus {
            background: #5e5a5a !important;
        }
    }
}


.ant-menu-submenu-popup {
    background: #313131 !important;

    > ul {
        background: #313131 !important;
    }
}

.ant-menu-submenu-popup {
    .ant-menu-item-group-list {
        .ant-menu-item {
            font-size: 18px !important;
            padding: 0 10px !important;
            margin-bottom: 5px !important;
    
            color: #bdbdbd;

            .anticon {
                font-size: 18px;
            }
    
            &:hover {
                color: #1b86f8;
            }
        }
    }
}

