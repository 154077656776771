.table_phone_numbers-form {
    thead {
        tr{
            th {
                background-color: #1417199e!important;
                &:nth-last-child(1) {
                    text-align: center;
                }
            }
        }
    }
    .anticon {
        color: #fff !important;
        cursor: pointer;
    }
    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background: rgba(133, 149, 208, 0.51) !important;
    }

    .ant-table-tbody > tr.ant-table-row > td{
        &:nth-last-child(1) {
            text-align: center;
        }
    }
}