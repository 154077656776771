.panel-control-classes {
    .mini-card {
        .container-panecontrol{
            .panecontrol-title{
                text-transform: capitalize;
            }
        }

        .ant-card-body {
            padding: 24px !important;
        }
    }
}
