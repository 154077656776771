@import '../../../assets/scss/utils';

.resource-detail-content {
    color: #ffffff;
    h2, h4 {
        color: #ffffff;
    }
    .resource-thumb {
        display: flex;
        margin: 0 auto 30px;
    }

    .edit-button {
        display: flex;
        margin-left: auto;
    }
}