.links-status {
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    color: white !important;
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 4px;
}

.links-status.PUBLISHED_WITH_DRAFT {
    background-color: #FFD12E;
}

.links-status.WAIT_PUBLISH {
    background-color: #FF5C00;
}

.links-status.WAITING_CORRECTION {
    background-color: #7A72F7;
}

.links-status.CREATED {
    background-color: #00D5EA;
}

.links-status.PUBLISHED {
    background-color: #2EA568;
}
