.payment-create-form {

    .coach-info-container {

        .coach-name,
        .coach-code,
        .coach-new-code,
        .payment-type,
        .payment-recurrent,
        .value,
        .expiration-date,
        .description {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            margin-bottom: 15px;
        }

        .coach-payment-type,
        .coach-unit-code,
        .inp-code,
        .expiration-description
         {
            display: flex;
            gap: 10px;
            justify-content: space-between;
        }

        .generate-code {
            align-self: center;
            width: 100%;
        }

        .expiration-date {
            .ant-calendar-picker-icon {
                margin-top: 6px;
                color: #FFF;
            }
        }

    }
    .coach-infor-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        gap: 10px;

        .cancel, .save {
            width: 100%;
        }
    }
}
