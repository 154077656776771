@import '../../assets/scss/utils';

.dashboard-container {
    @include displayFlex(row, nowrap, flex-start, flex-start, flex-start);
    width: 100vw;
    height: calc(100vh - #{$footer-height});

    .dev, .alpha {
        background-color: $dev;
    }

    .prod {
        background-color: $prod;
    }

    .dashboard-content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 20px 30px;
        overflow: auto;

        .ant-card-bordered {
            margin-bottom: 30px !important;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }

        .ant-spin-nested-loading > div > .ant-spin {
            max-height: 100% !important;
            
            background: rgba(#18191a, .8);
        }
    }

    .ant-card {
        background-color: #2b2b2b !important;
        box-shadow: 0px 0 7px 1px #000;

        .ant-card-head-title {
            color: #fcfbfc !important;
        }

        .ant-card-head {
            border-bottom: 1px solid #18191a;
        }
    }

    .ant-card-grid {
        box-shadow: 0 0 5px 0 #0000 !important;
    }

}