.select-banner {
    display: flex;
    flex: 2;
    padding: 10px;

    .select-view-type {
        width: 50%;
    }

    .expiration-date {
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 80px;

        .data-Picker {
            width: 100%;

            .ant-calendar-picker-icon {
                color: #FFF;
            }
        }
    };
};

.data-Picker {
    display: flex;
    align-items: center;
};
