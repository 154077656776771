.contact-details-modal{
    .ant-modal-content{
        width: max-content;
    
        .ant-modal-body {
            padding: 4px;
        }
    
        .ant-card {
            width: min-content;
            min-width: 650px;
        }
    
        .ant-card-head-title {
            white-space: normal;
        }
    }
}