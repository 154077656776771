.invoices-modal {
    display: flex;
    flex-direction: column;

    .invoices-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .div-input-date {
            overflow: -webkit-paged-x;
        }

        .ant-input {
            min-width: 185px;
        }
    }

    .ant-calendar-picker-icon {
        position: absolute !important;
        top: -46px !important;
        color: #fff;
    }

    .date-picker-clean-icon {
        width: 0!important;
        position: relative;
        left: -45px;
        color: #fff;
        cursor: pointer;
    }
}

    .invoices-modal-button {
        display: flex;
        justify-content: flex-end;
        button {
            margin-left: 10px;
        }

        .cancel {
            span {
                color: black !important;
            }
        }
    }
