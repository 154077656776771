@import "../../../assets/scss/utils";

.buttons-bottom {
    @include displayFlex(row, nowrap, flex-end, center, center);
    margin-top: 20px;

    button {
        margin-right: 20px;
    };
    .btn-cancel > span {
        color: black!important;
    };
    .btn-cancel:hover {
        background-color: #f8e8e8;
    };
};

