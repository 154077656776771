@import '../../../assets/scss/utils';

.PanelGraphBar-div {
    width: 1200px;
    @include displayFlex(column, nowrap, flex-start, flex-start, flex-end);
    .select-panelgraph {
        width: 97%;
        display: flex;
        justify-content: flex-end;    
    }
    .card-tooltip {
        border: outset!important;
        background-color: #fdfbfb !important;
        .desc {
            color: #ffffff;
        }
    }
    .recharts-text, .recharts-legend-item-text {
        color: #ffffff!important;
    }
}