@import '../../../assets/scss/utils';

.row-canceled-payments {
    &:nth-child(n) {
        text-decoration: line-through;
        text-decoration-color: #fff;
    }
}
.table-payments {
    thead {
        tr > th {
            &:nth-child(2),&:nth-child(3),&:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11),&:nth-child(13) {
                text-align: center!important;
            }
        }
    }
    tbody {
        tr > td {
            &:nth-child(2),&:nth-child(3),&:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11),&:nth-child(13) {
                text-align: center;
            }
        }
    }
}

.all-payments-pagination {
    @include displayFlex(row, nowrap, flex-end, center, center);
    margin-top: 20px !important;

    .ant-pagination-total-text {
        color: #fcfbfc;

        b {
            font-size: 16px;
            color: #f0ebf0;
        }
    }
}

.loose-status {
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    color: white !important;
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 4px;
}

.CREATED {
    background-color: #32c5d2;
}

.PAID {
    background-color: #008000;
}

.EXPIRED {
    background-color: #E26A6A;
}

.SENT {
    background-color: #2d7dac;
}
