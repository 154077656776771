@import "../../../assets/scss/utils";

.buttons-bottom {
    display: flex;
    justify-content: space-between;

    > div {
        display: flex;
    }

    margin-top: 20px;

    button {
        margin-right: 20px;
    };
    .btn-cancel > span {
        color: black!important;
    };
    .btn-cancel:hover {
        background-color: #f8e8e8;
    };

    .banner-size-info {
        color: #c7c7c7;
    }
};

.banner-edit {
    .ant-form-item-label {
        label {
            color: white;
        }
    }
}
