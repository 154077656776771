.attachments_list {
    max-height: 270px;
    overflow-y: auto;
    padding: 0 10px;
};

.btn-add {
    color: white;
    justify-content: flex-end;
    display: flex;
    margin-top: 5px;
};

.container_item {
    display: flex;
    flex-direction: column;

    & > div {
        margin-top: 20px;
    };
};

.attachment_header {
    display: flex;
    color: white;
    justify-content: space-between;
    padding: 5px 15px;
    align-items: center;    
};

.container_input {
    padding: 0px 10px;
};

.upload-link {
    margin-left: "5px";
    color: #1e90ff;
    cursor: "pointer";
};