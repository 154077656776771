.textarea-message {
    height: 120px !important;
}

.notification-radio-group {
    display: flex !important;
}

.flex-container {
    margin: 15px 0;
    > span {
        margin-right: 15px;
    }
}

.new-notification-button {
    margin: 5px 0;
}

.section-container {
    span {
        font-size: 0.9rem;
    }
}

.notifications-filters-bar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 15px 0;
}

.notifications-filters-container {
    display: flex;
    align-items: flex-end;

    .input-filter {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
    }

}

.editor-container {
    .editor-buttons {
        background-color: #2b2b2b;
        border-bottom: 1px solid #5c5c5c;
    
        button {
            font-family: 'Georgia', serif;
            background-color: transparent;
            height: 30px;
            width: 30px;
            color: #fff;
            cursor: pointer;
            border: none;
            border-right: 1px solid #5c5c5c;
        }
    }
    
    .editor-input {
        background-color: #2b2b2b;
        border: none;
        padding: 8px 10px;
        height: 150px;
        position: relative;
        overflow-y: auto;

        .DraftEditor-root {
            height: 100%;

            .DraftEditor-editorContainer {
                height: 100%;
            }
            
            .public-DraftEditor-content {
                height: 100%;
            }
        }
    }
}

