@import '../../../assets/scss/utils';

.container-profile-filter {
    @include displayFlex(row, nowrap, flex-end, center, center);
    .ant-input-search {
        width: 300px;
    }
    .ant-btn-primary {
        margin-left: 10px;
    }

    margin-bottom: 15px;
}