.ant-calendar-input-wrap, .ant-calendar-picker-input{
    display: none!important;
    position: absolute;
}
.input-masked-date-date-activation, .input-masked-date-date-expiration {
    margin: 0px!important;
    margin-left: 5px!important;
    width: 100%!important;
}
.input-activation{
    margin-top: 5px;
}
.ant-calendar-picker-icon {
    cursor: pointer;
}
.input-masked-date-date-activation {
    margin-left: 0px!important;
    margin-right: 10px!important;
} 
.input-masked-date-date-expiration {
    margin-left: 10px!important;
}
.date-picker-masked-date-date-activation{
    position: absolute!important;
    right: 76%!important;
    width: 100px!important;

    div {
        position: relative;
        width: 40px!important;
        height: 97%!important;
        left: 20%;
    }

    .ant-calendar-picker-icon {
        position: relative!important;
        right: -537%!important;
        top: 1px!important;
    }
}

.date-picker-masked-date-date-expiration{
    position: absolute!important;
    right: 27%!important;
    width: 100px!important;

    div {
        position: relative;
        width: 40px!important;
        height: 97%!important;
        left: 20%;
    }

    .ant-calendar-picker-icon {
        position: relative!important;
        top: 1px!important;
        right: -575%!important;
    }
}