div.ant-modal-content > div.ant-modal-body > form.coache-edit-container > fieldset:nth-child(2) > div.trio-group{
    .email-create-coache {
        width: calc(50% - 5px)!important;
    }
    .select-contry, .input-cpf {
        width: calc(50%/2 - 5px)!important;
    }
}

.text-cpf-create-coach {
    font-size: 11px;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;
    position: relative;
    left: 77%;
    width: 140px;
}
.h6-exist-user {
    color: #ffffff;
    font-weight: 800;
    font-size: 0.74rem;
    padding: 5px;
}