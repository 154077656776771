.index {
    display: grid;
    text-align: center;
    width: 30%;
};

.index-enable {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
};

.checkbox-categories {
    display: flex;
    justify-content: center;
    margin: 10px;
    align-items: center;
    width: 30%;
};

.buttons-bottom {
    margin-top: 20px;

    button {
        margin-right: 20px;
    };
    .btn-cancel > span {
        color: black!important;
    };
    .btn-cancel:hover {
        background-color: #f8e8e8;
    };
};