@import '../../../assets/scss/utils';

.filter-content-contact-us {
    @include displayFlex(column, nowrap, flex-end, center, center);
    width: max-content;
    width: 100%;
    flex-direction: row-reverse!important;
    justify-content: flex-start;
    // margin-bottom: 20px!important;

    .search-input {
        width: 300px;
        margin-right: 5px!important;
    }
}