.modal-sales-edit-sf {

    .buttons-bottom-sales-sf {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        gap: 14px;

        button {
            width: 100%;
        }
    }

    .grid-info-sales-sf {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 12px;
        grid-row-gap: 14px;
    }
}