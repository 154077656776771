.collapse-success {
    .ant-collapse-header {
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
    }

    .table-url-success, .table-url-error{
        th {
            background: #2b2b2b !important;
            border-bottom: 1px solid #17191a !important;
        }
        .ant-table-tbody > tr > td {
            color: #fff!important;
        }
    }
}

.collapse-error {
    .ant-collapse-header {
        color: #a94442;
        background-color: #a9444233;
        border-color: #ebccd1;
    }
}

.buttons-import-result {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    .button-color-import > span{
        color: #312e2e!important;
    }
    button {
        margin-left: 20px;
    }
}

.button-url-shorteners-resend {
    position: relative;
    left: 80%;
    margin-top: 10px;
}

button.cancel-results-url-shorteners {
    color: #17191a!important;
}

.modal-crate-url-shorteners > div.ant-modal-content > div.ant-modal-body > button {
    margin-top: 10px!important;
    position: relative;
    left: 80%;
    span {
        color: #17191a!important;
    }
}
.modal-crate-url-shorteners > div.ant-modal-content > div.ant-modal-body > div > div:nth-child(1) > div.ant-collapse-content.ant-collapse-content-active > div > div > div > div > div > div > div > table > tbody {
    tr:hover{
        background: #2c72abc7!important;
        td:nth-child(n) {
            background: #2c72abc7!important;
        }
    }
}
.modal-crate-url-shorteners > div.ant-modal-content > div.ant-modal-body > div > div.ant-collapse-item.ant-collapse-item-active.collapse-error > div.ant-collapse-content.ant-collapse-content-active > div > div > div > div > div > div > div > table > tbody {
    tr:hover{
        background: #2c72abc7!important;
        td:nth-child(n) {
            background: #2c72abc7!important;
        }
    }
}
.invisible-button {
    display: none;
    visibility: hidden;
}

.table-url-success, .table-url-error {
    .ant-table-body {
        overflow: auto;
        &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
            background-color: #F5F5F5;
        }
        
        &::-webkit-scrollbar{
            width: 3px;
            height: 8px;
            border-radius: 12px;
            background-color: #F5F5F5;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #555;
        }
    }
}
