.video_upload {
    margin-top: 8px;
    padding: 8px;
    border: 2px solid white;
    border-radius: 10px;
    font-weight: bold;
    color: white;
};

.upload_btn {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 10px;
    border: 2px solid white;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    color: white;
};

