@import '../../../assets/scss/utils';

.filter-content2 {
    @include displayFlex(row, nowrap, flex-start, flex-end, flex-end);
    padding: 10px 0;
    margin-bottom: 25px !important;

    .search-input {
        width: 300px;
    }

    .btn-search {
        margin-bottom: 5px;
        margin-right: 10px;
    }

    .input-filter {
        @include displayFlex(column, nowrap, flex-end, flex-start, flex-start);
        margin-right: 10px;

        label {
            color: #e1e1e1;
        }
    }

    

    .select-filter {
        width: 150px;
    }

    .ant-select-selection--multiple {
        min-width: 150px;
        max-width: unset;
    }
}