.pagination-url-shorteners {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px!important;
    .ant-pagination-item {
        display: none;
    }
    li.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon, .ant-pagination-jump-prev-custom-icon {
        display: none!important;
    }
}
.table-url-shorteners {
    margin-top: 20px;
}

div.ant-table-wrapper.table-url-shorteners > div > div > div > div > div > table > thead > tr {
    th:not(:nth-child(4)){
        width: 100px!important;
    }
    th:last-child {
        text-align: center;
    }
}
div.ant-table-wrapper.table-url-shorteners > div > div > div > div > div > table > tbody > tr {
    td:last-child {
        text-align: center;
    }
}
.ant-popover-message-title {
    max-width: 300px;
}
.span-code-url-shorteners {
    font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;
    font-weight: 800;
    text-transform: uppercase;
}