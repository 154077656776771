.mini-card {
    background: rgba(0, 0, 0, 0.65)!important;
    color: #ffffff!important;
    // padding: 0;
    margin-bottom: 3px!important;
    .ant-card-head{
        font-size: 20px;
        color: #ffffff!important;
    }
    .ant-card-grid {
        height: 110px;
        width: 220px;
        margin: 5px 15px 5px 15px;
        background-color: azure;
        padding: 0!important;
    }

    .ant-card-grid > div {
        width: 100%;
        height: 100%;
        padding: 10px!important;
    }
    .container-panecontrol{
        font-weight: bold;
        background-color: #3362f3;
        text-align: end;
        color: #ffffff;
    
        .panecontrol-results{
            font-size: 26px;
            display: block;
        }
    
        .panecontrol-title{
            display: block;
            line-height: 1.35;
        }
    }
}

.ant-card-bordered{
    border: 2px solid black!important
}

.contact-details-modal .ant-modal-content .ant-modal-body{
    padding: 0;
}