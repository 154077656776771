.blocklist-container {
    .searchbar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0 5px;

        margin-left: auto;

        .ant-input-search {
            width: 300px;
        }
    }

    th:last-child {
        width: 30px;
    }

    td:last-child {
        text-align: center;
    }

}

.pagination {
    display: flex;
    justify-content: flex-end;

    .ant-pagination-item {
        display: none;
    }
}

.ant-modal-content {
    .ant-btn span {
        color: black;
    }

    .ant-btn-primary span {
        color: white;
    }
}