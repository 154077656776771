.container-url-shorteners {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .container-search {
        display: flex;
        align-items: center;
        .search-input {
            width: 400px;
            margin-right: 10px;
        }
    }
}