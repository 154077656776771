.success > .ant-modal-content > .ant-modal-body {
    .ant-modal-confirm-title {
        color: #dff0d8!important;
    }
}

.info > .ant-modal-content > .ant-modal-body {
    .ant-modal-confirm-title {
        color: #d9edf7!important;
    }
}


.warning > .ant-modal-content > .ant-modal-body {
    .ant-modal-confirm-title {
        color: #fcf8e3!important;
    }
}

.error > .ant-modal-content > .ant-modal-body {
    .ant-modal-confirm-title {
        color: red!important;
    }
}

.error, .warning, .info, .success {
    .ant-modal-content > .ant-modal-body {
        background-color: #969696!important;
    }
    .ant-modal-confirm-body > i {
        font-size: 18px!important;
    }
    .ant-modal-confirm-title {
        text-transform: uppercase;
    }
    .ant-modal-confirm-content {
        color: black!important;
    }
}