.sales-registration-detail {
  margin: 0 auto;
  padding: 20px;
  background-color: #333;
  color: #fff;
  width: 100%;

  .input-container {
    .input-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;

      label {
        color: #fff;
      }

      input, .ant-input {
        background-color: #444;
        border-color: #555;
        color: #fff;
      }

    }

    .duo-input-row {
      display: flex;
      flex-direction: row;
      gap: 10px;

      > div {
        flex: 1;
      }

      .link-id {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        align-items: center;

        .input-link-id {
          width: 100%;
        }

        .open-checkout {
            margin-top: 20px;
            background: none;
            border: 1px solid #1890ff;

            span {
                color: #1890ff;
            }
        }
      }
    }

    .detail-subheader {
      color: #fff;
    }
  }

  .observations-row {
    margin-bottom: 20px;
    label {
      margin-bottom: 5px;
    }
  }

  .document-table {
    .ant-table {
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: #333;
    }
  }

  .action-buttons {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
}

.sales-registration-detail-header {
  display: flex;
  align-items: center;
  color: #fff;
}
