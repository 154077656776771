@import '../../../assets/scss/utils';

.register-units-pagination {

    @include displayFlex(row, nowrap, flex-end, center, center);
    margin: 10px!important;
}

.register-units-list {

    .ant-table-thead {
        tr {
            th:nth-child(2),th:nth-child(3),th:nth-child(4),th:nth-child(5){
                width: 180px!important;
                text-align: center!important;
            }

            th:last-child {
                width: 60px!important;
                text-align: center!important;
            }
        }
    }

    .ant-table-tbody {
        tr {
            td:nth-child(2),td:nth-child(3),td:nth-child(4),td:nth-child(5){
                text-align: center!important;
            }

            td:last-child {
                text-align: center!important;
            }
        }
    }
}
.anticon-check-units {
    color: #00ff00!important;
}
.anticon-close-units {
    color: #ff0000!important;
}