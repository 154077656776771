@import '../../../assets/scss/utils';

.coache-edit-container {

    .buttons-bottom {
        @include displayFlex(row, nowrap, flex-end, center, center);
        margin-top: 10px;
        
        button {
            margin-right: 20px;
        }
    }
    .centers-select{
        margin: 20px 0 !important;
    }

    .btn-cancel > span {
        color: black!important;
    }
    .ant-tabs-tab {
        margin-right: 3px!important;
    }
}

.ant-modal-body, .ant-modal-header, .ant-modal-footer, .ant-dropdown-menu, .ant-table-filter-dropdown-btns {
    background: #17191A!important;
    color: #ffffff!important;
    border-bottom: 1px solid #313131!important;
}
.ant-modal-title, .ant-tabs-tab, .ant-select-arrow, .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    color: #ffffff!important;
}
.ant-select-selection {
    background: #17191A!important;
    border: none!important;
    color: #ffffff;
}
.ant-modal-wrap  {
    background: rgba(57, 57, 57, 0.75);
}