.import-info {
    padding: 15px;
    .info-item {
        display: flex;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
        border: 1px solid #eee;
        width: 700px;

        p {
            margin: 0;
            display: flex;
            align-items: center;
            color: #fff;;
        }

        label {
            width: 200px;
            margin-right: 20px;
            padding: 10px;
            background: #0a0a0a;
            color: #fff!important;
            font-weight: 800;
        }
    }
}

.import-info-help {
    display: flex;
    padding: 5px;

    fieldset {
        height: 220px!important;
        legend {
            color: #fff;
            width: min-content;
        }

        .div-help {
            height: 155px;
            overflow-y: auto;
            word-wrap: break-word;
            p {
                color: #fff;
                font-weight: 600;
            }
            .icon-question-help {
                font-size: 16px;
                margin-left: 5px;
            }
        }
    }

}

.div-help::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.div-help::-webkit-scrollbar
{
    width: 7px;
    border-radius: 12px;
	background-color: #F5F5F5;
}

.div-help::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}