.import-preview {
    .import-result-table {
        td {
            padding: 10px 5px !important;
            text-align: center;
        }

        input {
            min-width: 110px;
        }

        importPreview .fullname,
        .email {
            min-width: 200px;
        }

        .cpf,
        .gender,
        .phone_number {
            min-width: 110px;
        }

        .country,
        .state {
            min-width: 80px;
        }

        .invalid-field {
            border: 1px solid red !important;
        }


        .import-actions {
            display: flex;

            button:nth-child(1) > span{
                color: #312e2e!important;  
            }

            button {
                &:first-child {
                    margin-right: 15px;
                }
            }
        }
    }

    .in-request {
        background-color: #e6f7fe;
    }
    .ant-descriptions-item-colon {
        width: 200px;
    }
}

.buttons-preview-import {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    .button-cancel-import > span {
        color: #312e2e!important;
    }
    button {
        margin-right: 10px;
    }
}
div.ant-popover-inner > div > div > div.ant-popover-buttons > button:nth-child(1) > span {
    color: #312e2e!important;
}

.import-result-table {
    .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr.row-table-importation:has(.duplicate-email) > td {
        background-color: rgb(133, 115, 35)!important
    }
}