.import-file-form {
    .upload-file {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #17191a;

        .file-name {
            display: inline-block;
            margin-left: 20px;
        }
    }
    
    .file-observation {
        color: #f0f0f0;
        margin-top: 10px;

        span {
            color: #1890ff;
        }
    }

    .btn-send {
        display: flex;
        margin-left: auto;
        margin-top: 30px;
    }
    .ant-btn:nth-child(2) > span {
        color: black!important;
    }
    .icon-delete-upload {
        margin-left: 10px;
    }
}
.date-picker-masked-date-form-date, .date-picker-masked-date-form-termino {
    .ant-calendar-picker-icon {
        position: absolute!important;
        top: -22px!important;
    }
}
