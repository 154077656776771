@import '../../../assets/scss/utils';

.pagination-profile-list {
    @include displayFlex(row, nowrap, flex-end, center, center);
    margin-top: 15px!important;
}
.tabel-profile-list{
    .ant-table-thead > tr > th:nth-child(2){
        width: 130px;
    }    
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        text-align: center;
        &:nth-child(1), &:nth-child(2){
            text-align: left;
        }
        &:nth-child(n+3){
            width: 100px;
        }
    }
}