@import '../../../assets/scss/utils';

.container-search-panelcoachingfilter {
    @include displayFlex(row, nowrap, flex-end, center, center);
    margin-bottom: 20px;

    .ant-input-affix-wrapper{
        width: 300px!important;
        margin-right: 5px;
    }

    .ant-btn-primary {
        background: #1b86f8;
    }
}