.form-group-input {
    padding: 10px;
};

.input-filter {
    width: 100%;
};

.block-2 {
    display: flex;
    width: 100%;
    height: 80px;
    align-items: self-end;
    justify-content: space-evenly;
};

.data-Picker {
    display: flex;
    align-items: center;
};

.checkbox-videos {
    display: flex;
    justify-content: center;
    margin: 10px;
    align-items: center;
    width: 40%;

    span {
        margin-left: 10px;
    };
};

.block-3 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
};

.input-days {
    text-align: center;
    width: 40%;
    align-items: center;
};

.uploads {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
};





