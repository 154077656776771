@import '../../../assets/scss/utils';

.filter-content2 {
    @include displayFlex(row, nowrap, flex-start, flex-end, flex-end);
    padding: 10px 0;
    margin-bottom: 25px !important;

    .btn-search {
        margin-bottom: 5px;
        margin-right: 10px;
    }

    .input-filter {
        @include displayFlex(column, nowrap, flex-end, flex-start, flex-start);
        margin-right: 10px;
        max-width: 300px;

        label {
            color: #e1e1e1;
        }
    }

    .ant-select-selection--multiple {
        min-width: 150px;
        max-width: unset;
    }
}

.search-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .button-search-container {
        top: 12px;
        position: relative;
    }
}