@import '../../../assets/scss/utils';


.row-canceled-sales {
    &:nth-child(n) {
        text-decoration: line-through;
        text-decoration-color: #fff;
    }
}
.table-sales {
    thead {
        tr > th {
            &:nth-child(2),&:nth-child(3),&:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11),&:nth-child(13) { 
                text-align: center!important;
            }
        }
    }
    tbody {
        tr > td {
            &:nth-child(2),&:nth-child(3),&:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11),&:nth-child(13) {
                text-align: center;
            }
        }
    }
}

.all-sales-pagination {
    @include displayFlex(row, nowrap, flex-end, center, center);
    margin-top: 20px !important;

    .ant-pagination-total-text {
        color: #fcfbfc;

        b {
            font-size: 16px;
            color: #f0ebf0;
        }
    }
}