.input-masked-date-input {
    margin: 0!important;
    margin-left: 20px!important;
}

.ant-calendar-input-wrap, .ant-calendar-picker-input{
    display: none!important;
    position: absolute;
}
.input-date-birthdate {
    .ant-calendar-picker-icon {
        cursor: pointer;
    }
    .date-picker-masked-date-input{
        position: absolute!important;
        width: 100px!important;
        right: 23%!important;
        .ant-calendar-picker-icon {
            position: relative!important;
            right: -225%!important;
            top: 0px!important;
        }
    }
}
.ant-calendar-picker-clear {
    display: none!important;
}
.label-payment {
    font-weight: 800;
}
.user-edit-phone-number {
    .react-phone-number-input__input{
        width: 282px;
        background-color: #2b2b2b;
        color: #fff;
        border: none;
        border-radius: 4px;
        height: 30px;
    }
    .react-phone-number-input__icon {
        border: none;
        height: 1.4em;
    }
}

.coach-id {
    .ant-input-group-addon:first-child {
        border: none;
        background-color: transparent;
    }
}
