.modal-crate-url-shorteners {
    div.ant-modal-content > div.ant-modal-body > div > div > button.ant-btn.ant-btn-default > span {
        color: black!important;
    }
    .container-buttons {
        display: flex;
        justify-content: flex-end;
    }

    .container-loading-buttons {
        display: flex;
        justify-content: space-between;
    }
    
    .container-buttons {
        display: flex;
        padding: 5px;
        margin-top: 5px;
        .ant-btn-default{
            margin-right: 10px!important;
        }
    }
}

.loading-modal-url {
    margin-top: 5px;
    font-size: 15px;
    margin-left: 6px;
    align-self: center;
}